





































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import StoreInfoService from '@/services/StoreInfoService'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import Logo from '@/components/Logo.vue'
import { DateTime } from '@/models/DateTime'
import moment from 'moment'
import { namespace } from 'vuex-class'
const ModeCartEdit = namespace('ModeCartEdit')

@Component({
  components: {
    LayoutCompact,
    Logo
  }
})
export default class TimeDeliveryAndDeadline extends Vue {
  public isDisable: boolean = false
  public message: string = ''
  public itemsDeliveryTimezone: any = []
  public itemsDeliveryTimezoneDisplay: any = []
  public deliveryName: any = []
  public moment: any = moment(new Date()).format('yyyy-MM-DD')
  public messageTimeZone: boolean = false

  private dateDelivery: string = ''
  private deliveryServiceId: string = ''
  private date = new DateTime()
  private options: any = []
  private store_uuid: any
  @ModeCartEdit.Action
  private SET_NEW_MODE_CART_EDIT!: (data: any) => any

  @Watch('dateDelivery')
  watchDateDeliveryChange() {
    this.deliveryName = this.itemsDeliveryTimezone[this.dateDelivery]
  }

  async created() {
    if (localStorage.getItem('delivery_date_normal_edit')) {
      this.dateDelivery =
        localStorage.getItem('delivery_date_normal_edit') || ''
    }
    if (localStorage.getItem('delivery_service_id_normal_edit')) {
      this.deliveryServiceId =
        localStorage.getItem('delivery_service_id_normal_edit') || ''
    }

    StoreInfoService.getStoreInfo().then(response => {
      if (response) {
        let items = response.data.items
        this.store_uuid = items.uuid
        let order_id = Number(this.$route.params.order_id)
        StoreInfoService.getDeliveryTimezoneChange(order_id).then(
          response => {
            if (response) {
              this.itemsDeliveryTimezone = response.data.items
              this.itemsDeliveryTimezoneDisplay = this.itemsDeliveryTimezone.display_service
              if (this.dateDelivery) {
                this.deliveryName = this.itemsDeliveryTimezone[
                  this.dateDelivery
                ]
              }
            }
          },
          (error: any) => {
            if (error) {
              if (error.response.status === 429) {
                this.manyRequest()
              }
              let errorData = error.response.data
              this.message = errorData.message[0].store_uuid[0]
            }
          }
        )
        if (items.delivery_date_type === 0) {
          this.date.value = moment().format('yyyy-MM-DD')
          this.date.text = moment().format('yyyy-MM-DD')
          this.dateDelivery = moment().format('yyyy-MM-DD')
          let date = new Date(this.date.value)
          if(date.getDay() != 0) {
            this.options.push(this.date)
          }
          this.isDisable = true
        } else {
          this.isDisable = false
          for (let i: number = 0; i <= items.delivery_date_range; i++) {
            this.date = new DateTime()
            this.date.value = moment()
              .add(i, 'days')
              .format('yyyy-MM-DD')
            this.date.text = moment()
              .add(i, 'days')
              .format('yyyy-MM-DD')
            let date = new Date(this.date.value)
            if(date.getDay() != 0) {
              this.options.push(this.date)
            }
          }
        }
      }
    })
  }

  public async setUp() {
    if (!this.dateDelivery || !this.deliveryServiceId) {
      this.message = 'お届け日と 配達便は必須項目です。'
    } else {
      await StoreInfoService.getValidateDeadline(
        this.deliveryServiceId,
        this.dateDelivery
      ).then(
        (res: any) => {
          if (res) {
            StoreInfoService.getInfoDeliveryTimezone(
              this.deliveryServiceId
            ).then(async response => {
              if (response) {
                let items: any = response.data.items
                let deliveryServiceId = items.id
                localStorage.setItem(
                  'delivery_service_id_normal_edit',
                  deliveryServiceId
                )
                this.SET_NEW_MODE_CART_EDIT(false)
                this.$router.push({
                  name: 'order-edit',
                  params: { order_id: this.$route.params.order_id }
                })
                localStorage.setItem(
                  'delivery_date_normal_edit',
                  this.dateDelivery
                )
                localStorage.setItem(
                  'delivery_service_id_normal_edit',
                  this.deliveryServiceId
                )
              }
            })
          }
        },
        (error: any) => {
          if (error) {
            if (error.response.status === 429) {
              this.manyRequest()
            }
            if (error.response.status === 440) {
              if (error.response.data.message[0] === 'DEADLINE_TIME_EXCEED') {
                this.messageTimeZone = true
              }
            }
          }
        }
      )
    }
  }

  private manyRequest() {
    localStorage.clear()
    this.$bvModal.show(`too-many-request`)
  }
}
